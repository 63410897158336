export const useEnvironment: () => Environment = () => {
  const {
    VITE_AUTH0_AUDIENCE,
    VITE_AUTH0_CLIENT_ID,
    VITE_AUTH0_DOMAIN,
    VITE_AUTH0_SCOPE,
    VITE_ENABLE_PRODUCTION_PROFILING,
    VITE_COCKPIT_URL,
    VITE_CONMON_URL,
    VITE_CSS_URL,
    VITE_OMA_URL,
    VITE_PORTAL_API_URL,
    VITE_DEVICE_MANAGEMENT_URL,
    VITE_INVOICE_TOOL_URL,
    DEV,
  } = import.meta.env;

  return {
    auth0Audience: VITE_AUTH0_AUDIENCE,
    auth0ClientId: VITE_AUTH0_CLIENT_ID,
    auth0Domain: VITE_AUTH0_DOMAIN,
    auth0Scope: VITE_AUTH0_SCOPE,
    enableProductionProfiling: VITE_ENABLE_PRODUCTION_PROFILING === 'true',
    isDev: DEV,
    cockpitUrl: VITE_COCKPIT_URL,
    invoiceToolUrl: VITE_INVOICE_TOOL_URL,
    conmonUrl: VITE_CONMON_URL,
    cssUrl: VITE_CSS_URL,
    omaUrl: VITE_OMA_URL,
    portalApiUrl: VITE_PORTAL_API_URL,
    deviceManagementUrl: VITE_DEVICE_MANAGEMENT_URL,
  };
};

export type Environment = {
  auth0Audience: string;
  auth0ClientId: string;
  auth0Domain: string;
  auth0Scope: string;
  enableProductionProfiling: boolean;
  isDev: boolean;
  cockpitUrl: string;
  conmonUrl: string;
  cssUrl: string;
  omaUrl: string;
  portalApiUrl: string;
  deviceManagementUrl: string;
  invoiceToolUrl: string;
};
